var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-2 mt-4 ml-0", staticStyle: { "max-width": "1120px" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "end" } },
        [
          _c(
            "v-col",
            { staticClass: "flex-grow-0 pt-0", attrs: { cols: "" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": true,
                    "nudge-width": 100,
                    "open-on-hover": "",
                    "offset-y": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      !_vm.hideActions
                        ? {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                !_vm.hideActions
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              depressed: "",
                                              height: "46",
                                              color: "primary",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { staticClass: "mr-1" }, [
                                          _vm._v("add"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !_vm.$language
                                                ? "Add Question"
                                                : "প্রশ্ন তৈরি করুন"
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language
                                  ? "What type of question you want to create?..."
                                  : "আপনি কি ধরনের প্রশ্ন তৈরি করতে চান?..."
                              ) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    _vm._l(_vm.questionTypesF, function (questionType, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: index,
                          attrs: {
                            to: questionType.route,
                            disabled: questionType.disabled,
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(questionType.title)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticStyle: {
            "border-bottom-color": "#0b2e13",
            "padding-bottom": "30px",
            "padding-left": "14px",
            "padding-right": "1%",
          },
        },
        [
          _c(
            "v-tabs",
            [
              _c(
                "v-tab",
                {
                  on: {
                    click: function ($event) {
                      _vm.searchType = "Single Question"
                    },
                  },
                },
                [_vm._v("Single Question")]
              ),
              _c(
                "v-tab",
                {
                  on: {
                    click: function ($event) {
                      _vm.searchType = "Group Question"
                    },
                  },
                },
                [_vm._v("Group Question")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ml-0 mb-4", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 right-border",
              staticStyle: {
                "max-width": "65.2%",
                "padding-right": "2% !important",
              },
            },
            [
              _c("v-text-field", {
                staticStyle: { height: "46px" },
                attrs: {
                  flat: "",
                  solo: "",
                  "prepend-inner-icon": "search",
                  placeholder: !_vm.$language
                    ? "Search by question text"
                    : "প্রশ্নপত্রের বিষয় নিয়ে সার্চ করুন",
                  "hide-details": "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search(0, true)
                  },
                },
                model: {
                  value: _vm.searchKey,
                  callback: function ($$v) {
                    _vm.searchKey = $$v
                  },
                  expression: "searchKey",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-0 right-border",
              staticStyle: {
                "max-width": "24%",
                "padding-right": "2% !important",
              },
            },
            [
              _c("v-autocomplete", {
                staticClass: "tag-select",
                staticStyle: { height: "46px" },
                attrs: {
                  items: _vm.topics,
                  "item-value": "id",
                  flat: "",
                  solo: "",
                  placeholder: !_vm.$language
                    ? "Select Tags"
                    : "ট্যাগ সিলেক্ট করুন",
                  "data-qa": "question-topic",
                  multiple: "",
                  "hide-details": "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search(0, true)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ item, index }) {
                      return [
                        index === 0
                          ? _c(
                              "v-chip",
                              {
                                staticStyle: {
                                  background: "rgba(0, 153, 220, 0.4)",
                                },
                                attrs: { label: "" },
                              },
                              [
                                item.length < 15
                                  ? _c("span", [_vm._v(_vm._s(item))])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.text
                                            .htmlToText()
                                            .substring(0, 15)
                                        ) + "..."
                                      ),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                        index === 1
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.searchTopics.length - 1) +
                                    " others) "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.searchTopics,
                  callback: function ($$v) {
                    _vm.searchTopics = $$v
                  },
                  expression: "searchTopics",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", staticStyle: { "max-width": "10%" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text q-search",
                  staticStyle: { height: "46px", "border-radius": "4px" },
                  attrs: {
                    block: "",
                    depressed: "",
                    tile: "",
                    color: "primary",
                    "x-large": "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.search(0, true)
                    },
                  },
                },
                [_vm._v(_vm._s(!_vm.$language ? "Search" : "খুজুন") + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.searchType === "Single Question"
            ? _c(
                "v-card",
                { staticClass: "pa-5" },
                [
                  _c("v-data-table", {
                    attrs: {
                      "items-per-page": _vm.itemsPerPage,
                      value: _vm.selected,
                      "show-select": _vm.selectable,
                      headers: !_vm.$language ? _vm.headers : _vm.headersBN,
                      items: _vm.questions,
                      "item-key": "question_id",
                      "hide-default-footer": "",
                      loading: _vm.isLoading,
                    },
                    on: { input: _vm.updateSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.topics`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.questionDetails.topics
                                      .map((topic) => topic.topic_name)
                                      .join(", ")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.questionDetails.difficulty`,
                          fn: function ({ item }) {
                            return [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showDifficulty(
                                        item.questionDetails.difficulty
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.text`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "my-7" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "table-question",
                                      attrs: {
                                        to: {
                                          name: _vm.getRouteName(item),
                                          params: _vm.getRouteParams(item),
                                        },
                                        target: _vm.hideActions
                                          ? "_blank"
                                          : "_self",
                                      },
                                    },
                                    [
                                      item.questionDetails.text
                                        ? _c("div", {
                                            staticClass: "ml-4 overflow-hidden",
                                            staticStyle: {
                                              "max-height": "9vh",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.format(
                                                  item.questionDetails.text
                                                )
                                              ),
                                            },
                                          })
                                        : item.questionDetails.file_url
                                        ? _c(
                                            "div",
                                            { staticClass: "ml-3" },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: item.questionDetails
                                                    .file_url,
                                                  "max-width": "150",
                                                  "max-height": "150",
                                                  contain: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticStyle: { color: "#9d9d9d" } }, [
                                _vm._v(
                                  " created " +
                                    _vm._s(
                                      _vm.date_diff(
                                        item.questionDetails.created_at
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.type`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.questionDetails
                                      ? !_vm.$language
                                        ? _vm.questionTypesText[
                                            item.questionDetails.type
                                          ]
                                        : _vm.questionTypesTextBN[
                                            item.questionDetails.type
                                          ]
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        !_vm.hideActions
                          ? {
                              key: `item.actions`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "primary",
                                                          to: {
                                                            name: "question-create",
                                                            query: {
                                                              duplicate_from:
                                                                item.question_id,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("content_copy"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Duplicate question"
                                              : "প্রশ্নের প্রতিলিপি করুন"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.isOwner &&
                                  !_vm.isJustShared(item.questionDetails.topics)
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "primary",
                                                                  to: {
                                                                    name: "question-edit",
                                                                    params: {
                                                                      questionId:
                                                                        item.question_id,
                                                                    },
                                                                  },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("edit"),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.$language
                                                      ? "Edit question"
                                                      : "প্রশ্ন এডিট করুন"
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "primary",
                                                                  loading:
                                                                    _vm
                                                                      .deleting[
                                                                      item
                                                                        .question_id
                                                                    ],
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showDeleteDialog(
                                                                        item.question_id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("delete"),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.$language
                                                      ? "Delete question"
                                                      : "প্রশ্ন ডিলিট করুন"
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                  _c(
                    "div",
                    { staticClass: "pt-2 d-flex justify-center" },
                    [
                      _c("v-pagination", {
                        attrs: {
                          length: _vm.pageCount,
                          "total-visible": _vm.totalVisible,
                          color: "primary",
                        },
                        on: { input: _vm.paginationDataLoad },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.searchType === "Group Question"
            ? _c(
                "v-card",
                { staticClass: "v-light-card pa-5" },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { flat: "", multiple: "" },
                      model: {
                        value: _vm.panels,
                        callback: function ($$v) {
                          _vm.panels = $$v
                        },
                        expression: "panels",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between mb-2 pl-1 pr-5",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(!_vm.$language ? "Essay(s)" : "প্রবন্ধ")
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(!_vm.$language ? "Actions" : "পরিবর্তন")
                            ),
                          ]),
                        ]
                      ),
                      _vm.essayLoading
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          })
                        : _vm._e(),
                      _vm._l(_vm.essays, function (item, i) {
                        return _c(
                          "v-expansion-panel",
                          { key: i },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                attrs: {
                                  color: _vm.panels.includes(i)
                                    ? "primary"
                                    : "white",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.getQuestionsForEssay(i)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.togglePanel(i)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.panels.includes(
                                                      i
                                                    )
                                                      ? "white"
                                                      : "primary",
                                                  },
                                                },
                                                [_vm._v("keyboard_arrow_down ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center",
                                  },
                                  [
                                    item.text.length > _vm.truncateTextAfter
                                      ? _c(
                                          "div",
                                          {
                                            class: _vm.panels.includes(i)
                                              ? "white--text pr-16"
                                              : "pr-16",
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.text
                                                                      .htmlToText()
                                                                      .slice(
                                                                        0,
                                                                        _vm.truncateTextAfter
                                                                      )
                                                                  ) +
                                                                  "....... "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.text.htmlToText()
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            class: _vm.panels.includes(i)
                                              ? "white--text"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.text.htmlToText()) +
                                                " "
                                            ),
                                          ]
                                        ),
                                    _c(
                                      "div",
                                      [
                                        false
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  to: {
                                                    name: "question-group-edit",
                                                    params: {
                                                      essayId: item.essay_id,
                                                    },
                                                  },
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.panels.includes(i)
                                                          ? "white"
                                                          : "primary",
                                                    },
                                                  },
                                                  [_vm._v("edit ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              loading:
                                                _vm.deleting[item.essay_id],
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                _vm.showDeleteDialog(
                                                  item.essay_id,
                                                  _vm.deleteEssayText(item)
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.panels.includes(i)
                                                    ? "white"
                                                    : "primary",
                                                },
                                              },
                                              [_vm._v("delete ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _vm.hasQuestionGroup(item)
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        item.question_group,
                                        function (subItem, index) {
                                          return _c(
                                            "v-row",
                                            {
                                              key: index,
                                              staticClass:
                                                "border-0 border-b border-solid border-default",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _vm.selectable
                                                ? _c("v-simple-checkbox", {
                                                    attrs: {
                                                      value:
                                                        _vm.isFluentQuestionSelected(
                                                          item,
                                                          subItem
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectedGroupQuestions(
                                                          item,
                                                          subItem
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "question-group-viewer",
                                                _vm._b(
                                                  { staticClass: "pa-2" },
                                                  "question-group-viewer",
                                                  _vm.paramsQuestionGroup(
                                                    item,
                                                    subItem
                                                  ),
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("v-data-table", {
                                      attrs: {
                                        value: _vm.selected,
                                        "show-select": _vm.selectable,
                                        headers: !_vm.$language
                                          ? _vm.headers
                                          : _vm.headersBN,
                                        items: item.questions,
                                        "items-per-page": 1000,
                                        "item-key": "question_id",
                                        "hide-default-footer": "",
                                        loading: _vm.isLoading,
                                      },
                                      on: {
                                        input: _vm.updateSelectedGroup,
                                        "item-selected": function ($event) {
                                          return _vm.useEssayIndex($event, i)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: `item.topics`,
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.questionDetails.topics
                                                        .map(
                                                          (topic) => topic.text
                                                        )
                                                        .join(", ")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.questionDetails.difficulty`,
                                            fn: function ({ item }) {
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.showDifficulty(
                                                          item.questionDetails
                                                            .difficulty
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.text`,
                                            fn: function ({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "my-7" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "table-question",
                                                        attrs: {
                                                          to: {
                                                            name: _vm.getRouteName(
                                                              item
                                                            ),
                                                            params:
                                                              _vm.getRouteParams(
                                                                item
                                                              ),
                                                          },
                                                          target:
                                                            _vm.hideActions
                                                              ? "_blank"
                                                              : "_self",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveGroupQuestionIndex(
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        item.questionDetails
                                                          .text
                                                          ? _c("div", {
                                                              staticClass:
                                                                "ml-4 overflow-hidden",
                                                              staticStyle: {
                                                                "max-height":
                                                                  "9vh",
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.format(
                                                                      item
                                                                        .questionDetails
                                                                        .text
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : item.questionDetails
                                                              .file_url
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-3",
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    src: item
                                                                      .questionDetails
                                                                      .file_url,
                                                                    "max-width":
                                                                      "150",
                                                                    "max-height":
                                                                      "150",
                                                                    contain: "",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: `item.type`,
                                            fn: function ({ item }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      !_vm.$language
                                                        ? _vm.questionTypesText[
                                                            item.questionDetails
                                                              .type
                                                          ]
                                                        : _vm
                                                            .questionTypesTextBN[
                                                            item.questionDetails
                                                              .type
                                                          ]
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-2 d-flex justify-center" },
                    [
                      _c("v-pagination", {
                        attrs: {
                          length: _vm.pageCount,
                          "total-visible": _vm.totalVisible,
                          "active-color": "primary",
                        },
                        on: { input: _vm.paginationDataLoad },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                      !_vm.$language
                        ? _c("div", [
                            _vm._v(
                              " Showing " +
                                _vm._s(_vm.essays.length) +
                                " of " +
                                _vm._s(_vm.totalQuestionCount) +
                                " results "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.totalQuestionCount) +
                                " টির মধ্যে " +
                                _vm._s(_vm.essays.length) +
                                " টি ফলাফল দেখানো হচ্ছে "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("dialog-delete", {
        attrs: {
          deleting: _vm.deleting[_vm.questionToDelete],
          item: _vm.questionToDelete,
          "item-text": _vm.dialogItemText,
        },
        on: {
          "confirm-remove": function ($event) {
            return _vm.deleteItem(_vm.questionToDelete.id)
          },
        },
        model: {
          value: _vm.questionDeleteDialog,
          callback: function ($$v) {
            _vm.questionDeleteDialog = $$v
          },
          expression: "questionDeleteDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
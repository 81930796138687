import { Logger } from "/global/utils/helpers";

export const TopicsProvider = {
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  created() {
    this.listTopics();
  },
  data() {
    return {
      loadingTopics: false,
      topicsLoadFailed: false,
      topicsLoadError: null
    };
  },
  computed: {
    topics() {
      return this.$store.state.questionBank.topics.topics;
    }
  },
  inject: ["$courseId", "$courseOwnerId"],
  methods: {
    async listTopics() {
      try {
        this.loadingTopics = true;
        await this.$store.dispatch("questionBank/topics/list", {
          owner_id: this.$courseOwnerId(),
          course_id: this.$courseId()
        });
      } catch (e) {
        Logger.log(e);
        this.topicsLoadFailed = true;
        this.topicsLoadError = e.message;
      }
    }
  }
};

export const QuestionCreator = {
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      savingQuestion: false
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  methods: {
    async save() {
      try {
        this.savingQuestion = true;
        if (this.question.questionDetails.questionType === "desc") {
          this.$delete(this.question, "answers");
        } else {
          this.$delete(this.question, "answerPreference");
        }
        await this.$store.dispatch("questionBank/create", {
          question: {
            ...this.question,
            courseId: this.courseId,
            ownerId: this.$courseOwnerId()
          }
        });
        this.$emit("saved");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.savingQuestion = false;
      }
    }
  }
};

export const QuestionUpdater = {
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      updatingQuestion: false
    };
  },
  inject: ["$courseId", "$courseOwnerId"],
  methods: {
    async update(questionId, question) {
      try {
        this.updatingQuestion = true;
        if (question.questionDetails.questionType === "desc") {
          this.$delete(question, "answers");
        } else {
          this.$delete(question, "answerPreference");
        }
        await this.$store.dispatch("questionBank/update", {
          questionId,
          questionData: question,
          courseId: this.$courseId(),
          ownerId: this.$courseOwnerId()
        });
        this.$emit("submitted");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.updatingQuestion = false;
      }
    },
  }
};

export const QuestionProvider = {
  data() {
    return {
      loadingQuestion: {}
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  methods: {
    async fetchQuestion(questionId) {
      try {
        this.$set(this.loadingQuestion, questionId, true);
        return this.$store.dispatch("questionBank/get", {
          questionId,
          owner_id: this.$courseOwnerId(),
          course_id: this.$courseId()
        });
      } catch (e) {
        this.$notify(e, { type: "error" });
      } finally {
        this.$nextTick(() =>
          this.$set(this.loadingQuestion, questionId, false)
        );
      }
    }
  }
};

export const EquationFinder = {
  data(){
    return {

    }
  },
  methods: {
    async retrieveEquationsAndModifyQuestions(questions){
      if(questions===undefined) return;
      for(let question of questions){
        let resp = await this.findEquationsFromQuestionText(question.questionDetails.text, question.question_id);
        question.questionDetails.text = resp;
      }
    },
    async findEquationsFromQuestionText(text, id){
      if(text===undefined) return text;

      const domObject = new DOMParser().parseFromString(text, 'text/html');
      let equationPlaceholders = domObject.querySelectorAll('.eq-placeholder');
      if(equationPlaceholders.length == 0) return text;
      for(let [index, item] of equationPlaceholders.entries()){
        let equationText = await this.$store.dispatch("questionBank/getEquationForQuestion", {
          question_id: id,
          index: index
        });
        let tempElement = document.createElement('div');
        tempElement.innerHTML = await this.blobToString(equationText);
        item.parentElement.replaceChild(tempElement.firstElementChild, item);
      }

      return domObject.body.innerHTML;
    },
    // This should probably be in utils/helpers.js
    // Since hasan said it's on his branch so I didn't put it in utils
    async blobToString(blob){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = (event) => {resolve(event.target.result)};
        reader.readAsText(blob);
      })
    }
  }
}

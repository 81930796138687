<template>
  <v-container class="pt-2 mt-4 ml-0" style="max-width: 1120px">
    <v-row align="center" justify="end">
      <v-col cols="" class="flex-grow-0 pt-0">
        <v-menu
          :close-on-content-click="true"
          :nudge-width="100"
          open-on-hover
          offset-y
        >
          <template v-if="!hideActions" v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!hideActions"
              depressed
              v-bind="attrs"
              height="46"
              color="primary"
              v-on="on"
            >
              <v-icon class="mr-1">add</v-icon>
              {{ !$language ? "Add Question" : "প্রশ্ন তৈরি করুন" }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-subtitle
                >{{
                  !$language
                    ? "What type of question you want to create?..."
                    : "আপনি কি ধরনের প্রশ্ন তৈরি করতে চান?..."
                }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-for="(questionType, index) in questionTypesF"
              :key="index"
              :to="questionType.route"
              :disabled="questionType.disabled"
            >
              <v-list-item-title>{{ questionType.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      style="
        border-bottom-color: #0b2e13;
        padding-bottom: 30px;
        padding-left: 14px;
        padding-right: 1%;
      "
    >
      <v-tabs>
        <v-tab @click="searchType = 'Single Question'">Single Question</v-tab>
        <v-tab @click="searchType = 'Group Question'">Group Question</v-tab>
      </v-tabs>
    </v-row>
    <v-row align="center" class="ml-0 mb-4">
      <v-col
        style="max-width: 65.2%; padding-right: 2% !important"
        class="pa-0 right-border"
      >
        <v-text-field
          v-model="searchKey"
          flat
          solo
          prepend-inner-icon="search"
          :placeholder="
            !$language
              ? 'Search by question text'
              : 'প্রশ্নপত্রের বিষয় নিয়ে সার্চ করুন'
          "
          hide-details
          style="height: 46px"
          @keyup.enter="search(0, true)"
        ></v-text-field>
      </v-col>
      <v-col
        style="max-width: 24%; padding-right: 2% !important"
        class="pa-0 right-border"
      >
        <v-autocomplete
          v-model="searchTopics"
          :items="topics"
          item-value="id"
          flat
          solo
          :placeholder="!$language ? 'Select Tags' : 'ট্যাগ সিলেক্ট করুন'"
          style="height: 46px"
          class="tag-select"
          data-qa="question-topic"
          multiple
          hide-details
          @keyup.enter="search(0, true)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              label
              style="background: rgba(0, 153, 220, 0.4)"
            >
              <span v-if="item.length < 15">{{ item }}</span>
              <span v-else
                >{{ item.text.htmlToText().substring(0, 15) }}...</span
              >
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ searchTopics.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col style="max-width: 10%" class="pa-0">
        <v-btn
          block
          depressed
          tile
          color="primary"
          class="white--text q-search"
          style="height: 46px; border-radius: 4px"
          x-large
          @click="search(0, true)"
          >{{ !$language ? "Search" : "খুজুন" }}
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-card v-if="searchType === 'Single Question'" class="pa-5">
        <v-data-table
          :items-per-page="itemsPerPage"
          :value="selected"
          :show-select="selectable"
          :headers="!$language ? headers : headersBN"
          :items="questions"
          item-key="question_id"
          hide-default-footer
          :loading="isLoading"
          @input="updateSelected"
        >
          <template v-slot:[`item.topics`]="{ item }">
            {{
              item.questionDetails.topics
                .map(topic => topic.topic_name)
                .join(", ")
            }}
          </template>
          <template v-slot:[`item.questionDetails.difficulty`]="{ item }"
            ><p>
              {{ showDifficulty(item.questionDetails.difficulty) }}
            </p></template
          >

          <template v-slot:[`item.text`]="{ item }">
            <div class="my-7">
              <router-link
                :to="{
                  name: getRouteName(item),
                  params: getRouteParams(item)
                }"
                :target="hideActions ? '_blank' : '_self'"
                class="table-question"
              >
                <div
                  v-if="item.questionDetails.text"
                  style="max-height: 9vh"
                  class="ml-4 overflow-hidden"
                  v-html="format(item.questionDetails.text)"
                ></div>
                <div v-else-if="item.questionDetails.file_url" class="ml-3">
                  <v-img
                    :src="item.questionDetails.file_url"
                    max-width="150"
                    max-height="150"
                    contain
                  ></v-img>
                </div>
              </router-link>
            </div>
            <div style="color: #9d9d9d">
              created {{ date_diff(item.questionDetails.created_at) }}
            </div>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            {{
              item.questionDetails
                ? !$language
                  ? questionTypesText[item.questionDetails.type]
                  : questionTypesTextBN[item.questionDetails.type]
                : ""
            }}
          </template>

          <template v-if="!hideActions" v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  :to="{
                    name: 'question-create',
                    query: { duplicate_from: item.question_id }
                  }"
                  v-on="on"
                >
                  <v-icon>content_copy</v-icon>
                </v-btn>
              </template>
              <span>{{
                !$language ? "Duplicate question" : "প্রশ্নের প্রতিলিপি করুন"
              }}</span>
            </v-tooltip>
            <span v-if="isOwner && !isJustShared(item.questionDetails.topics)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    :to="{
                      name: 'question-edit',
                      params: { questionId: item.question_id }
                    }"
                    v-on="on"
                    ><v-icon>edit</v-icon></v-btn
                  >
                </template>
                <span>{{
                  !$language ? "Edit question" : "প্রশ্ন এডিট করুন"
                }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    :loading="deleting[item.question_id]"
                    @click="showDeleteDialog(item.question_id)"
                    v-on="on"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </template>
                <span>{{
                  !$language ? "Delete question" : "প্রশ্ন ডিলিট করুন"
                }}</span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
        <div class="pt-2 d-flex justify-center">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            color="primary"
            @input="paginationDataLoad"
          ></v-pagination>
        </div>
      </v-card>
      <v-card
        v-else-if="searchType === 'Group Question'"
        class="v-light-card pa-5"
      >
        <v-expansion-panels v-model="panels" flat multiple>
          <div
            style="width: 100%"
            class="d-flex justify-space-between mb-2 pl-1 pr-5"
          >
            <div>{{ !$language ? "Essay(s)" : "প্রবন্ধ" }}</div>
            <div>{{ !$language ? "Actions" : "পরিবর্তন" }}</div>
          </div>
          <v-progress-circular
            v-if="essayLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-expansion-panel v-for="(item, i) in essays" :key="i">
            <v-expansion-panel-header
              :color="panels.includes(i) ? 'primary' : 'white'"
              @click.stop="getQuestionsForEssay(i)"
            >
              <template v-slot:actions>
                <v-btn icon @click="togglePanel(i)">
                  <v-icon :color="panels.includes(i) ? 'white' : 'primary'"
                    >keyboard_arrow_down
                  </v-icon>
                </v-btn>
              </template>
              <div class="d-flex justify-space-between align-center">
                <div
                  v-if="item.text.length > truncateTextAfter"
                  :class="panels.includes(i) ? 'white--text pr-16' : 'pr-16'"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{
                          item.text.htmlToText().slice(0, truncateTextAfter)
                        }}.......
                      </div>
                    </template>
                    <span>{{ item.text.htmlToText() }}</span>
                  </v-tooltip>
                </div>
                <div v-else :class="panels.includes(i) ? 'white--text' : ''">
                  {{ item.text.htmlToText() }}
                </div>
                <div>
                  <!--                  following edit button intentionally hidden for now-->
                  <v-btn
                    v-if="false"
                    icon
                    :to="{
                      name: 'question-group-edit',
                      params: { essayId: item.essay_id }
                    }"
                    @click.stop
                  >
                    <v-icon :color="panels.includes(i) ? 'white' : 'primary'"
                      >edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    :loading="deleting[item.essay_id]"
                    @click.stop="
                      showDeleteDialog(item.essay_id, deleteEssayText(item))
                    "
                  >
                    <v-icon :color="panels.includes(i) ? 'white' : 'primary'"
                      >delete
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="hasQuestionGroup(item)">
                <v-row
                  v-for="(subItem, index) in item.question_group"
                  :key="index"
                  no-gutters
                  class="border-0 border-b border-solid border-default"
                >
                  <v-simple-checkbox
                    v-if="selectable"
                    :value="isFluentQuestionSelected(item, subItem)"
                    @click="selectedGroupQuestions(item, subItem)"
                  ></v-simple-checkbox>
                  <question-group-viewer
                    class="pa-2"
                    v-bind="paramsQuestionGroup(item, subItem)"
                  ></question-group-viewer>
                </v-row>
              </div>
              <v-data-table
                v-else
                :value="selected"
                :show-select="selectable"
                :headers="!$language ? headers : headersBN"
                :items="item.questions"
                :items-per-page="1000"
                item-key="question_id"
                hide-default-footer
                :loading="isLoading"
                @input="updateSelectedGroup"
                @item-selected="useEssayIndex($event, i)"
              >
                <template v-slot:[`item.topics`]="{ item }">
                  {{
                    item.questionDetails.topics
                      .map(topic => topic.text)
                      .join(", ")
                  }}
                </template>
                <template v-slot:[`item.questionDetails.difficulty`]="{ item }"
                  ><p>
                    {{ showDifficulty(item.questionDetails.difficulty) }}
                  </p></template
                >

                <template v-slot:[`item.text`]="{ item }">
                  <div class="my-7">
                    <router-link
                      :to="{
                        name: getRouteName(item),
                        params: getRouteParams(item)
                      }"
                      :target="hideActions ? '_blank' : '_self'"
                      class="table-question"
                      @click.native="saveGroupQuestionIndex(i)"
                    >
                      <div
                        v-if="item.questionDetails.text"
                        style="max-height: 9vh"
                        class="ml-4 overflow-hidden"
                        v-html="format(item.questionDetails.text)"
                      ></div>
                      <div
                        v-else-if="item.questionDetails.file_url"
                        class="ml-3"
                      >
                        <v-img
                          :src="item.questionDetails.file_url"
                          max-width="150"
                          max-height="150"
                          contain
                        ></v-img>
                      </div>
                    </router-link>
                  </div>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  {{
                    !$language
                      ? questionTypesText[item.questionDetails.type]
                      : questionTypesTextBN[item.questionDetails.type]
                  }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="pt-2 d-flex justify-center">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            active-color="primary"
            @input="paginationDataLoad"
          ></v-pagination>
          <div v-if="!$language">
            Showing {{ essays.length }} of {{ totalQuestionCount }} results
          </div>
          <div v-else>
            {{ totalQuestionCount }} টির মধ্যে {{ essays.length }} টি ফলাফল
            দেখানো হচ্ছে
          </div>
        </div>
      </v-card>
    </div>
    <dialog-delete
      v-model="questionDeleteDialog"
      :deleting="deleting[questionToDelete]"
      :item="questionToDelete"
      :item-text="dialogItemText"
      @confirm-remove="deleteItem(questionToDelete.id)"
    ></dialog-delete>
  </v-container>
</template>

<script>
import QuestionGroupViewer from "#ecf/question-bank/components/GroupQuestion/QuestionGroupViewer.vue";
import essayApi from "#ecf/question-bank/api/essay";
import DialogDelete from "@ecf/global/components/DialogDelete";
import moment from "moment";
import katex from "katex/dist/katex.mjs";
import _ from "lodash";

import { EquationFinder } from "#ecf/question-bank/mixins";
import essay from "#ecf/question-bank/api/essay";

const HEADERS = [
  {
    text: "Question Text or Image",
    value: "text",
    width: "40%",
    sortable: false
  },
  { text: "Type", value: "type", width: "20%", sortable: false },
  {
    text: "Difficulty",
    value: "questionDetails.difficulty",
    width: "10%",
    sortable: false
  },
  {
    text: "Topic(s)",
    align: "center",
    value: "topics",
    width: "15%",
    sortable: false
  },
  { text: "Action", align: "center", value: "actions", sortable: false }
];

const HEADERS_BN = [
  {
    text: "প্রশ্নের টেক্সট বা ছবি",
    value: "text",
    width: "40%",
    sortable: false
  },
  { text: "টাইপ", value: "type", width: "20%", sortable: false },
  {
    text: "ডিফিকাল্টি",
    value: "questionDetails.difficulty",
    width: "10%",
    sortable: false
  },
  {
    text: "টপিক",
    align: "center",
    value: "topics",
    width: "15%",
    sortable: false
  },
  { text: "পরিবর্তন", align: "center", value: "actions", sortable: false }
];

const HEADERSFORESSAY = [
  { text: "Essay", value: "text", width: "100%", sortable: false }
];

const HEADERSFORSINGLE = [
  {
    text: "Question Text or Image",
    value: "questionDetails.text",
    width: "50%",
    sortable: false
  },
  { text: "Type", value: "type", width: "30%", sortable: false },
  {
    text: "Difficulty",
    value: "questionDetails.difficulty",
    width: "25%",
    sortable: false
  },
  {
    text: "Topic(s)",
    align: "center",
    value: "topics",
    width: "15%",
    sortable: false
  }
];

const QTYPES = [
  {
    title: "Single Question",
    route: "questions/create",
    type: "single"
  },
  {
    title: "Group Question",
    route: "questions/group/create",
    type: "essay"
  }
];

const TYPES = {
  "Single Question": "single",
  "Group Question": "essay"
};
const math = /\$([^$]*)\$/g;
export default {
  name: "QuestionList",
  components: { DialogDelete, QuestionGroupViewer },
  mixins: [EquationFinder],
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    categorySearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectedGroupQuestion: false,
      isSelectedEssays: {},
      editorOptions: {
        modules: {
          toolbar: false
        }
      },
      questionTypesText: {
        mcq: "Multiple Choice Question",
        fib: "Fill In The Blanks",
        desc: "Descriptive Question"
      },
      questionTypesTextBN: {
        mcq: "বহুনির্বাচনী প্রশ্ন",
        fib: "শূন্যস্থান পূরণ",
        desc: "বর্ণনামূলক প্রশ্ন"
      },
      searchTopics: [],
      searchType: "Single Question",
      searchKey: "",
      headers: HEADERS,
      headersBN: HEADERS_BN,
      headersSingle: HEADERSFORSINGLE,
      isLoading: false,
      questionTypes: QTYPES,
      questions: [],
      essays: [],
      essayLoading: false,
      questionsForEssay: [],
      itemsPerPage: 5,
      page: 1,
      pageCount: 0,
      pageStart: 0,
      totalQuestionCount: 0,
      pageStop: 0,
      toggle: false,
      expand: false,
      openPanels: [],
      panels: [],
      essayChecks: [],
      essayIndeterminateCheck: [],
      deleting: {},
      essayPanels: {},
      totalVisible: 6,
      questionToDelete: undefined,
      questionDeleteDialog: false,
      dialogItemText: undefined,
      qt_types: [
        { en: "Single Question", bn: "সিঙ্গেল প্রশ্নপত্র" },
        { en: "Group Question", bn: "গ্রুপ প্রশ্নপত্র" }
      ],
      localSelected: []
    };
  },
  computed: {
    truncateTextAfter() {
      return Math.floor(this.$vuetify.breakpoint.width * 0.06);
    },
    questionTypesF() {
      return [
        {
          title: !this.$language ? "Single Question" : "সিঙ্গেল প্রশ্নপত্র",
          route: "questions/create",
          type: "single"
        },
        {
          title: !this.$language ? "Group Question" : "গ্রুপ প্রশ্নপত্র",
          route: "questions/group/create",
          type: "essay"
        }
      ];
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
    topics() {
      return this.$store.state.questionBank.topics.topics;
    },
    types() {
      return this.qt_types;
    },
    questionTypeFullForm(shortForm) {
      if (shortForm === "mcq") return "Multiple Choice Question";
      else if (shortForm === "fib") return "Fill In The Blanks";
      else return null;
    }
  },
  inject: ["$courseId", "$courseOwnerId"],
  watch: {
    searchType: {
      handler(value) {
        this.search(0, true, false);
      }
    }
  },
  async created() {
    await this.$store.dispatch("questionBank/topics/list", {
      owner_id: this.$courseOwnerId(),
      course_id: this.$courseId()
    });
    let local = [];
    this.selected.forEach(question => {
      if (question.question_id) {
        local.push(question);
      }
    });
    this.localSelected = local;
  },
  async mounted() {
    let savedKey = this.$store.state.questionBank.lastSearched.key;
    let savedType = this.$store.state.questionBank.lastSearched.type;
    let savedTopics = this.$store.state.questionBank.lastSearched.topics;
    let gIndex = this.$store.state.questionBank.lastSearched.groupQuestionIndex;
    let essayId = this.$store.state.questionBank.lastSearched.essayId;
    if (savedType || savedTopics.length || savedKey) {
      this.searchKey = savedKey;
      this.searchTopics = [...savedTopics];
      this.searchType = savedType;
      this.page = this.$store.state.questionBank.lastSearched.page;
      this.pageCount = Math.ceil(this.totalQuestionCount / this.itemsPerPage);
      await this.search((this.page - 1) * this.itemsPerPage, true, true);
      if (gIndex >= 0) {
        this.panels = [gIndex];
        await this.getQuestionsForEssay(gIndex);
      }
      if (essayId != "") {
        this.essayPanels[essayId].panel = 0;
        this.toggleEssayPanel(essayId);
      }
    }
  },
  methods: {
    deleteEssayText(item) {
      if ((item["question_group"]?.length ?? 0) > 1) {
        return !this.$language
          ? "Essay and all linked questions"
          : "গ্রুপ প্রশ্ন এবং সকল সংযুক্ত প্রশ্ন";
      } else {
        return !this.$language ? "Essay" : "গ্রুপ প্রশ্ন";
      }
    },
    hasQuestionGroup(data) {
      return (data["question_group"]?.length || -1) > 0;
    },
    paramsQuestionGroup(item, subItem) {
      let content = subItem["item_text"];
      let questions = item.questions.filter(
        q => q.collection_id === subItem.id
      );
      let answers = item.answers.filter(a => a.collection_id === subItem.id);
      let files = item.files;
      let readonly = true;

      questions.sort((a, b) => {
        return a.collection_order - b.collection_order;
      });

      return {
        content,
        questions,
        answers,
        files,
        readonly
      };
    },
    isFluentQuestionSelected(item, subItem) {
      return (
        this.selected.findIndex(
          x =>
            x.essay_id === item.essay_id &&
            (x?.question_group?.id ?? "") === subItem.id
        ) >= 0
      );
    },
    selectedGroupQuestions(item, subItem) {
      let itemIndex = this.selected.findIndex(
        x =>
          x.essay_id === item.essay_id &&
          (x?.question_group?.id ?? "") === subItem.id
      );
      let out = [...this.selected];
      if (itemIndex < 0) {
        let lastCommonEssayIndex = out.findLastIndex(
          x => x.essay_id === item.essay_id
        );
        let essayItem = _.cloneDeep(item);
        essayItem.question_group = item.question_group.find(
          x => x.id === subItem.id
        );
        essayItem.answers = item.answers?.filter(
          x => x.collection_id === subItem.id
        );
        essayItem.questions = item.questions?.filter(
          x => x.collection_id === subItem.id
        );
        essayItem.questions.sort((a, b) => {
          return a.collection_order - b.collection_order;
        });
        if (lastCommonEssayIndex < 0) out.push(essayItem);
        else out.splice(lastCommonEssayIndex + 1, 0, essayItem);
      } else {
        out.splice(itemIndex, 1);
      }
      this.$emit("update:selected", out);
    },
    showDifficulty(data) {
      if (this.$language) {
        if (data === "Easy") {
          return "সহজ";
        } else if (data === "Medium") {
          return "মধ্যম";
        } else if (data === "Hard") {
          return "কঠিন";
        }
      }
      return data;
    },
    isJustShared(questionTopics) {
      // 'just' to specify it is not owned and only shared
      let retVal = false;
      for (let i = 0; i < questionTopics.length; i++) {
        for (let j = 0; j < this.topics.length; j++) {
          if (questionTopics[i].topic_id === this.topics[j].id) {
            return this.topics[j].shared;
          }
        }
      }
      // if the topicId is not in the backend provided topics, then surely that is not shared
      // isJustShared is primarily being used as a restricting mechanism (i.e.- true restricts more than false),
      // so returning false for malicious/inapplicable topics by default is concerning, but
      // then again, maliciously added/mimicked topicId would not be able to get questions from backend in the first place
      return retVal;
    },
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" }
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
    date_diff(date) {
      let diff = moment(date).from(
        moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      );
      return diff;
    },
    paginationDataLoad(event) {
      this.search((event - 1) * this.itemsPerPage);
    },
    showDeleteDialog(itemId, type = !this.$language ? "Question" : "প্রশ্ন") {
      this.questionToDelete = { id: itemId };
      this.questionDeleteDialog = true;
      this.dialogItemText = type;
    },
    async deleteItem(item) {
      if (this.dialogItemText == "Question") {
        await this.deleteQuestion(item);
      } else {
        await this.deleteGroup(item);
      }
    },
    async deleteQuestion(questionId) {
      try {
        this.$set(this.deleting, questionId, true);
        await this.$store.dispatch("questionBank/delete", {
          questionId: questionId,
          courseId: this.$courseId(),
          ownerId: this.$courseOwnerId()
        });
        await this.search(0, true);
      } catch (e) {
        this.$notify(e, { type: "error" });
      } finally {
        this.$set(this.deleting, questionId, false);
      }
    },
    async deleteGroup(essayId) {
      try {
        this.$set(this.deleting, essayId, true);
        let resp = await essayApi.delete(
          essayId,
          this.$courseId(),
          this.$courseOwnerId()
        );

        if (resp?.undeleted?.length > 0) {
          this.$root.$emit("alert", [
            undefined,
            "This group question is being used in exam(s) and so was not deleted"
          ]);
        }
      } catch (e) {
        this.$notify(e, { type: "error" });
      } finally {
        await this.search(0, true);
        this.$forceUpdate();
        this.$set(this.deleting, essayId, false);
      }
    },
    togglePanel(i) {
      if (!this.openPanels.includes(i)) {
        this.openPanels.push(i);
      } else {
        this.openPanels = this.openPanels.filter(item => item !== i);
      }
    },
    async selectAllQuestionForEssay(val, i, item, essayId) {
      if (val) {
        await this.getQuestionsForEssay(
          i,
          item,
          essayId,
          this.$courseId(),
          this.$courseOwnerId()
        );
        let index = 0;
        for (index = 0; index < this.essays[i].questions.length; index++) {
          this.selected.push(this.essays[i].questions[index]);
        }
      } else {
        let index = 0;
        for (index = 0; index < this.essays[i].questions.length; index++) {
          this.selected = this.selected.filter(
            e => e.question_id !== this.essays[i].questions[index].question_id
          );
        }
      }
    },
    async getQuestionsForEssay(i) {
      if (this.loading) return;
      if (!this.essays[i].questions) {
        this.isLoading = true;
        try {
          const params = {
            essay_id: this.essays[i].essay_id,
            course_id: this.$courseId(),
            owner_id: this.$courseOwnerId()
          };
          let quesForEssay = await this.$store.dispatch(
            "questionBank/getQuestionsForEssay",
            params
          );
          this.$set(this.essays[i], "questions", quesForEssay.questions);
          this.$set(this.essays[i], "answers", quesForEssay.answers);
          this.$set(this.essays[i], "files", quesForEssay.files);
          this.$set(
            this.essays[i],
            "question_group",
            quesForEssay.question_group
          );
        } catch (e) {
          this.$root.$emit("alert", [undefined, e.message]);
        } finally {
          this.isLoading = false;
        }
      }
    },
    saveGroupQuestionIndex(index) {
      this.$store.commit("questionBank/saveSearchInput", {
        key: this.searchKey,
        topics: this.searchTopics,
        type: this.searchType,
        index: index
      });
    },
    saveEssayForMix(essayId) {
      this.$store.commit("questionBank/saveSearchInput", {
        key: this.searchKey,
        topics: this.searchTopics,
        type: this.searchType,
        essayId: essayId,
        page: this.page
      });
    },
    async toggleEssayPanel(essayId) {
      if (this.essayPanels[essayId].questions.length) return;
      try {
        this.essayPanels[essayId].loading = true;
        this.essayPanels[essayId].questions = await this.$store.dispatch(
          "questionBank/getQuestionsForEssay",
          {
            essay_id: essayId,
            course_id: this.$courseId(),
            owner_id: this.$courseOwnerId()
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.essayPanels[essayId].loading = false;
      }
    },
    async search(offset = 0, isFirstCall = false, savedPage = false) {
      if (
        this.searchKey === "" &&
        this.searchTopics.length === 0 &&
        !this.searchType
      ) {
        this.$store.commit("questionBank/clearSearchInput");
        return;
      }
      this.openPanels = [];
      this.panels = [];
      try {
        this.isLoading = true;
        const params = {
          topics: this.searchTopics,
          course_id: this.$courseId(),
          owner_id: this.$courseOwnerId(),
          offset: offset,
          items_per_page: this.itemsPerPage,
          is_first_call: isFirstCall,
          search_key: this.searchKey
        };

        if (this.nextToken) params.token = this.nextToken;
        if (this.searchType) params.type = TYPES[this.searchType];
        var qresp = "";
        if (params.type === "single") {
          qresp = await this.$store.dispatch("questionBank/search", params);
          await this.retrieveEquationsAndModifyQuestions(qresp.questions);
          this.questions = qresp.questions;
        } else if (params.type === "essay") {
          this.essays = [];
          this.essayLoading = true;
          qresp = await this.$store.dispatch("questionBank/search", params);
          this.essays = qresp.essays;
          this.essayLoading = false;
        } else {
          this.essays = [];
          this.essayLoading = true;
          qresp = await this.$store.dispatch("questionBank/search", params);
          this.essays = qresp.essays;
          this.essayLoading = false;
          await this.retrieveEquationsAndModifyQuestions(qresp.questions);
          this.questions = qresp.questions;
          this.questions.forEach(item => {
            if (item.essay_id) {
              this.$set(this.essayPanels, item.essay_id, {
                panel: undefined,
                questions: [],
                loading: false
              });
            }
          });
        }

        if (isFirstCall) {
          this.totalQuestionCount = qresp.totalQuestionCount;
          this.pageCount = Math.ceil(
            qresp.totalQuestionCount / this.itemsPerPage
          );
          this.page = savedPage ? this.page : 1;
        }

        this.pageStart = offset + 1;
        this.pageStop = Math.min(
          offset + this.itemsPerPage,
          this.totalQuestionCount
        );

        this.$store.commit("questionBank/saveSearchInput", {
          key: this.searchKey,
          topics: this.searchTopics,
          type: this.searchType,
          page: this.page
        });
      } catch (e) {
        this.$store.commit("questionBank/clearSearchInput");
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.isLoading = false;
      }
    },
    isFullySelected(essayIndex) {
      let index = 0;
      for (
        index = 0;
        index < this.essays[essayIndex].questions.length;
        index++
      ) {
        // a ques for that essay
        let essayQuestion = this.essays[essayIndex].questions[index];
        let j = 0;
        let match = 0;
        for (j = 0; j < this.selected.length; j++) {
          if (this.selected[j].question_id === essayQuestion.question_id) {
            match = match + 1;
          }
        }
        if (match < 1) {
          return false;
        }
      }
      return true;
    },
    updateSelected(selectedQuestions) {
      let out = [...this.selected];
      let selectedNonFluent = selectedQuestions.filter(
        item => item.question_id !== undefined && item.essay_id === undefined
      );
      let forSplice = [];
      out.forEach((item, index) => {
        if (item.question_id) {
          let i = selectedQuestions.findIndex(
            x => x.question_id === item.question_id
          );
          if (i < 0) {
            forSplice.push(index);
          }
        }
      });
      forSplice.reverse();
      forSplice.forEach(x => {
        out.splice(x, 1);
      });
      let existingQuestions = out.reduce((acc, curr) => {
        if (curr.question_id) acc[curr.question_id] = true;
        return acc;
      }, {});

      selectedNonFluent.forEach(item => {
        if (!(item.question_id in existingQuestions)) {
          out.push(item);
        }
      });
      this.$emit("update:selected", out);
    },
    updateSelectedGroup(selectedQuestions) {
      let out = [...this.selected];
      let selectedNonFluent = selectedQuestions.filter(
        item => item.question_id !== undefined && item.essay_id !== undefined
      );
      let forSplice = [];
      out.forEach((item, index) => {
        if (item.question_id) {
          let i = selectedNonFluent.findIndex(
            x => x.question_id === item.question_id
          );
          if (i < 0) {
            forSplice.push(index);
          }
        }
      });
      forSplice.reverse();
      forSplice.forEach(x => {
        out.splice(x, 1);
      });

      let existingQuestions = out.reduce((acc, curr) => {
        if (curr.question_id) acc[curr.question_id] = true;
        return acc;
      }, {});

      let existingEssays = out.reduce((acc, curr, index) => {
        if (curr.essay_id) acc[curr.essay_id] = index;
        return acc;
      }, {});

      selectedNonFluent.forEach(item => {
        if (!(item.question_id in existingQuestions)) {
          if (item.essay_id in existingEssays) {
            out.splice(existingEssays[item.essay_id] + 1, 0, item);
            existingEssays[item.essay_id]++;
          } else {
            existingEssays[item.essay_id] = out.length;
            out.push(item);
          }
        }
      });
      this.$emit("update:selected", out);
    },
    useEssayIndex(selectedQuestions, i) {
      if (this.isFullySelected(i)) {
        this.essayChecks[i] = true;
      }
    },
    getRouteName(item) {
      return item.questionDetails ? "question-show" : "question-group-show";
    },
    getRouteParams(item) {
      return item.questionDetails
        ? { questionId: item.question_id }
        : { essayId: item.essay_id };
    },
    isSelected(questionId) {
      return this.selected.some(item => item.question_id === questionId);
    },
    selectFromMix(questionData) {
      let updated = [];
      if (this.isSelected(questionData.question_id)) {
        updated = [...this.selected];
        updated.splice(
          updated.findIndex(
            item => item.question_id === questionData.question_id
          ),
          1
        );
      } else {
        updated = [...this.selected, questionData];
      }
      this.$emit("update:selected", updated);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/global/styles/scss/variables";

.v-pagination__item {
  color: $primary !important;
}

.v-pagination__item--active {
  color: white !important;
}

.v-tabs {
  border-bottom: solid 1px $primary;
}

.v-tab {
  color: #272727 !important;
  text-transform: none;
  font-size: 17px;
}

.v-tabs-slider {
  background-color: transparent;
}

.v-tab--active {
  background-color: #d6f3ff !important;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: $background;
}

.katex-display {
  display: inline-block !important;
}

.table-question {
  text-decoration: none !important;
  color: #3d3d3d !important;
}

.ql-container.ql-snow {
  border: none;
}
</style>
